import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Paper from '@mui/material/Paper';
import { useMediaQuery,useTheme } from '@mui/material';
import AthleteProfile from './AthleteProfile';
import { ClickableCell } from './Common'

function SelectedAthletes({userid, period}){
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm')); // Adjust breakpoint as needed

  const [athletes, setAthletes] = useState([]);
  const [openAthleteProfile, setOpenAthleteProfile] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState(0);

  const columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 120 ,
      renderCell: (params) => (
        <ClickableCell onClick={() => handleCellClick(params)}>
          {params.value}
        </ClickableCell>
      ),
    },
    { field: 'country', headerName: 'Country', flex: 1, minWidth: 100  },
    { field: 'gender', headerName: 'Gender', flex: 1, minWidth: 100  },
    { field: 'score', headerName: 'Score', flex: 0.5, minWidth: 85  },
    { field: 'results', headerName: 'Results', flex: 0.5, minWidth: 85  },
  ]

  useEffect(() => {
    fetch(`/api/picks/${userid}/${period}`)
      .then((res) => res.json())
      .then((data) => {
        setAthletes(data);
      });
  }, [userid,period]);  

  const handleCellClick = (params) => {
    setSelectedAthlete(params.id)
    setOpenAthleteProfile(true)
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
      <AthleteProfile open={openAthleteProfile} setOpen={setOpenAthleteProfile} athleteId = {selectedAthlete}/>

      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
              disableColumnMenu
              rows={athletes}
              columns={columns}
              initialState={{
                columns: {
                    columnVisibilityModel: {
                      id: false,
                      country: !isMobile,
                      gender: !isMobile,
                    },
                },
              }}
              density={'compact'}
              disableRowSelectionOnClick
              disableColumnSelector
              ignoreDiacritics
            />
        </Paper>
        </div>
    </React.Fragment>
  );
  
}

export default function PickSummary(userid, period) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button size="small" variant="outlined" onClick={handleOpen}>Show Picks</Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <SelectedAthletes userid={userid} period = {period}/>
        </DialogContent>

      </Dialog>
    </div>
  );
}
