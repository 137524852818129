import React, { useState, useEffect } from 'react'
// import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {  Dialog,  Button, DialogContent, DialogActions,  } from '@mui/material';
import AthleteProfile from './AthleteProfile';
import { ClickableCell } from './Common';
import StatusBar from './StatusBar';


export default function Triathletes({openSelectTriathletes, setOpenSelectTriathletes, pickedAthletes, pickSelectedAthletes }) {

  const [allAthletes, setAllAthletes] = useState([]);
  const [filteredAthletes, setFilteredAthletes] = useState([]);
  const [openAthleteProfile, setOpenAthleteProfile] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState(0);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [status, setStatus] = useState({ message: '', type: '' });

  useEffect(() => {
    setStatus({ message: `${rowSelectionModel.length} of ${10-pickedAthletes.length} athlete selected`, type: 'success' });
  }, [rowSelectionModel,pickedAthletes]);

  useEffect(() => {
    fetch(`/api/triathletes`)
      .then((res) => res.json())
      .then((data) => setAllAthletes(data));
  }, []);

  useEffect(() => {
    setFilteredAthletes(allAthletes.filter(athlete => !pickedAthletes.some(picked => picked.id === athlete.id)));
  }, [allAthletes, pickedAthletes]);

  const handlePickSelected = () => {
    pickSelectedAthletes(rowSelectionModel);
    setRowSelectionModel([])
    setOpenSelectTriathletes(false)
  }

  const handleCancel = () => {
    setRowSelectionModel([])
    setOpenSelectTriathletes(false)
  }

  const handleShowAthleteProfile = (params) => {
    setSelectedAthlete(params.id)
    setOpenAthleteProfile(true)
  }

  const handleRowSelection = (newRowSelectionModel) => {
      if (newRowSelectionModel.length + pickedAthletes.length > 10) {
        setStatus({ message: `You can only select ${10-pickedAthletes.length} athletes`, type: 'error' });
        return;
      }
      setRowSelectionModel(newRowSelectionModel);
  }   

  const columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 120, sortable: true, filterable: true,
      renderCell: (params) => (
        <ClickableCell onClick={() => handleShowAthleteProfile(params)}>
          {params.value}
        </ClickableCell>
      ),
    },
    { field: 'country', headerName: 'Country', flex: 0.5, minWidth: 100,sortable: true, filterable: true },
    { field: 'gender', headerName: 'Gender', flex: 0.5, minWidth: 100,sortable: true, filterable: true },
    { field: 'results', headerName: 'Results', flex: 0.5, minWidth: 100 },
    { field: 'score', headerName: 'Score', flex: 0.5, minWidth: 90 },
    { field: 'score_rank', headerName: 'Rank', flex: 0.5, minWidth: 90 },
    { field: 'on_start_list', headerName: 'On Start List', type: 'string', flex: 1.5, minWidth: 120 }
  ]

  return (
    <React.Fragment>
      <AthleteProfile open={openAthleteProfile} setOpen={setOpenAthleteProfile} athleteId = {selectedAthlete}/>
      <Dialog open={openSelectTriathletes} onClose={() => setOpenSelectTriathletes(false)} fullWidth maxWidth="lg" >
      <DialogContent>
        <StatusBar message={status.message} type={status.type} />
        <DataGrid
            rows={filteredAthletes}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            onRowSelectionModelChange={handleRowSelection}
            rowSelectionModel={rowSelectionModel}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 10 }},
              columns: { columnVisibilityModel: { id: false,},},
              filter: {
                filterModel: {
                  items: [ { field: 'on_start_list', operator: 'isNotEmpty', }, ],
                }
              }
            }}
            pageSizeOptions={[10, 15, 25, 50]}
            slots={{ toolbar: GridToolbar }}        
            slotProps={{ toolbar: { showQuickFilter: true,}, }}
          />
      </DialogContent>
      <DialogActions>
          <Button onClick = {handleCancel} color="primary">
              Cancel
          </Button>
          <Button onClick = {handlePickSelected} color="primary">
              Pick and Close
          </Button>
      </DialogActions>
      </Dialog>      
    </React.Fragment>
  );
}
