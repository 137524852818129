import { styled } from '@mui/material/styles';


export const ClickableCell = styled('div')({
  cursor: 'pointer',
  color: '#1976d2', // Example color for clickable cells
  '&:hover': {
    textDecoration: 'underline',
  },
});
