import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Rules = () => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <div>
              <h2>Rules for Fantasy Triathlon</h2>
              <p>Welcome to Fantasy Triathlon! Please review the rules below:</p>
              <ul>
                <li>
                  To play, sign in with Google. Upon signing in, a team will be created for you.
                  From the settings menu, you can modify your team's name and create up to three additional teams.
                </li>
                <li>The Summary page will show your scoring history.</li>
                <li>
                  Go to the Weekly Lineup page to submit your picks for the upcoming weekend.
                </li>
                <li>You can choose up to 10 athletes per week.</li>
                <li>
                  Selections can be made between Midnight EST on Monday and Noon on Friday.
                </li>
                <li>
                  Points are awarded on the Monday following the competitions based on the table below:
                </li>
              </ul>
              <table
                style={{
                  border: '1px solid black',
                  borderCollapse: 'collapse',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <thead>
                  <tr>
                    <th>Place</th>
                    <th>Ironman Championships</th>
                    <th>T100</th>
                    <th>Gold Tier and WTCS World Championship </th>
                    <th>Silver Tier and WTCS World Cup</th>
                    <th>Other</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>20 points</td>
                    <td>15 points</td>
                    <td>10 points</td>
                    <td>7 points</td>
                    <td>5 points</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>15 points</td>
                    <td>10 points</td>
                    <td>7 points</td>
                    <td>5 points</td>
                    <td>3 points</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>10 points</td>
                    <td>7 points</td>
                    <td>5 points</td>
                    <td>3 points</td>
                    <td>1 point</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>7 points</td>
                    <td>5 points</td>
                    <td>3 points</td>
                    <td>2 points</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>5th</td>
                    <td>5 points</td>
                    <td>5 points</td>
                    <td>3 points</td>
                    <td>1 point</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>6th</td>
                    <td>3 points</td>
                    <td>3 points</td>
                    <td>1 point</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>7th</td>
                    <td>2 points</td>
                    <td>2 points</td>
                    <td>1 point</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>8th</td>
                    <td>2 points</td>
                    <td>2 points</td>
                    <td>1 point</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>9th</td>
                    <td>1 point</td>
                    <td>1 point</td>
                    <td>1 point</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>10th</td>
                    <td>1 point</td>
                    <td>1 point</td>
                    <td>1 point</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Lower</td>
                    <td>No points</td>
                    <td>No points</td>
                    <td>No points</td>
                    <td>No points</td>
                    <td>No points</td>
                  </tr>
                </tbody>
              </table>
              <ul>
                <li>
                  The Ranking page lets you see how your team's picks compare to others.
                </li>
                <li>
                  Winners will be determined on December 31, 2025. Prizes are as follows:
                  <ul>
                    <li><strong>$100</strong> for First Place</li>
                    <li><strong>$50</strong> for Second Place</li>
                    <li><strong>$25</strong> for Third Place</li>
                  </ul>
                </li>
              </ul>
              <p>Have fun, and good luck from Kupala Tri!</p>
            </div>
            <div>
              <p>
                For questions or suggestions, please reach out to{' '}
                <a href="mailto:kupala.tri@gmail.com">kupala.tri@gmail.com</a>.
              </p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Rules;
