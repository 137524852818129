import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid'
import { Dialog, DialogContent, DialogActions, Button, Typography, Grid, Avatar } from '@mui/material';


const resultsColumns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'comp', headerName: 'Competition', flex: 3, minWidth: 250 },
  { field: 'place', headerName: 'Place', flex: 0.5, minWidth: 70 },
  { field: 'score', headerName: 'Score', flex: 0.5, minWidth: 70 },
  { field: 'total_time', headerName: 'Finish', flex: 0.5, minWidth: 80 },
  { field: 'swim', headerName: 'Swim', flex: 0.5, minWidth: 100 },
  { field: 'bike', headerName: 'Bike', flex: 0.5, minWidth: 100 },
  { field: 'run', headerName: 'Run', flex: 0.5, minWidth: 100 },
];

const DEFAULT_PROFILE = {'name':'N/A','results':[]}
export default function AthleteProfile({open, setOpen, athleteId}) {

  
  const [athleteInfo, setAthleteInfo] = React.useState(DEFAULT_PROFILE);

  React.useEffect(() => {
    if (!athleteId) return;
    
    fetch(`/api/triathlete/${athleteId}`)
      .then((res) => res.json())
      .then((data) => {
        setAthleteInfo(data);
      }).catch((error) => {
        console.log(`Error loading profile for ${athleteId}: ${error}`)
        setAthleteInfo(DEFAULT_PROFILE);
      });
  }, [athleteId]);  

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') { 
      setOpen(false); 
    }
  };

  return (
      <Dialog open={open} onClose={onClose} maxWidth="lg">
          {/* <DialogTitle> Player Profile</DialogTitle> */}
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4} container justifyContent="center" alignItems="center">
                <Avatar
                  alt={athleteInfo.name}
                  src={athleteInfo.img_url}
                  style={{ width: 150, height: 150 }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6"><b>Name:</b> {athleteInfo.name}</Typography>
                <Typography variant="h6"><b>Country:</b> {athleteInfo.country}</Typography>
                <Typography variant="h6"><b>Gender:</b> {athleteInfo.gender}</Typography>
                <Typography variant="h6"><b>Age:</b> {athleteInfo.age}</Typography>
                <Typography variant="h6"><b>Height:</b> {athleteInfo.height}</Typography>
                <Typography variant="h6"><b>Weight:</b> {athleteInfo.weight}</Typography>
                {/* Add more attributes as needed */}
              </Grid>
            </Grid>
            {/* <div style={{ height: 300, width: '100%', marginTop: '20px' }}> */}
              <DataGrid
                disableColumnMenu
                rows={athleteInfo.results}
                columns={resultsColumns}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
          
                pageSize={5}
                rowsPerPageOptions={[5]}
                density={'compact'}
                disableRowSelectionOnClick
                disableColumnSelector
                ignoreDiacritics
              />
            {/* </div> */}
          </DialogContent>

          <DialogActions>
              <Button onClick={onClose} color="primary">
                  OK
              </Button>
          </DialogActions>
      </Dialog>
  );
};

