import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Dashboard from './Dashboard';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey("66fabd132e4f6210162af550c94ee173Tz05NjkyMCxFPTE3NTYzMDc2MjEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=");

function initializeGoogleAnalytics() {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8GZYDH1CF5';
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-8GZYDH1CF5');
  };
}

initializeGoogleAnalytics();

const originalConsoleLog = console.log;

const logWithTimestamp = (message) => {
  const timestamp = new Date().toISOString();
  originalConsoleLog(`[${timestamp}] ${message}`);
};

console.log = logWithTimestamp;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Dashboard />
  </React.StrictMode>
);

// import reportWebVitals from './reportWebVitals';
// reportWebVitals(console.log);
