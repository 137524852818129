import React, { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import "./SplashScreen.css";

const SplashScreen = () => {
    const [isVisible, setIsVisible] = useState(true);
  
    useEffect(() => {
        const duration = 30000; // Total duration of confetti (ms)
        const end = Date.now() + duration;
    
        const interval = setInterval(() => {
          if (Date.now() > end || !isVisible) {
            clearInterval(interval);
          } else {
            confetti({
              particleCount: 100,
              spread: 50,
              startVelocity: 30,
              origin: { 
                x: 0.25 + Math.random() * 0.5, // Middle 50% of the screen (0.25 to 0.75)
                y: Math.random() * 0.3, // Top 30% of the screen (0 to 0.3)
              },
              zIndex: 9999, 
            });
          }
        }, 1000);
    
        return () => clearInterval(interval); // Cleanup on unmount
      }, [isVisible]);
    

    const handleClose = () => {
      setIsVisible(false);
    };
  
    if (!isVisible) return null;
  
    return (
      <div className="splash-container">
        <div className="splash-content">
        <h1>Welcome to the 2025 Triathlon Season!</h1>
        <p><strong>Compete, Win, and Celebrate!</strong></p>
        <ul>
            <li><strong>$100</strong> for the Champion</li>
            <li><strong>$50</strong> for the Runner-Up</li>
            <li><strong>$25</strong> for Third Place</li>
        </ul>
        <p>Don’t miss your chance! Sign up for a free account now.</p>
        <button className="cta-button" onClick={handleClose}>
            Join the Competition
        </button>
        </div>
      </div>
    );
  };
export default SplashScreen;
