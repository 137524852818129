import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

export default function UserProfile({userInfo, open, setOpen}) {

  const [teams, setTeams] = React.useState([]);
  
  React.useEffect(() => {
    if (!open)
      return
    fetch(`/api/user_players`)
      .then((res) => res.json())
      .then((data) => {
        setTeams(data);
      });
  },[open ]);  
  
  const handleAddRow = () => {
    const newRow = { id: teams.length *-1, name: 'New Team', description: ''  };
    setTeams([...teams, newRow]);
  };

  const handleTeamNameChange = (id, newName, newDescription) => {
    const updatedRows = teams.map((row) => (row.id === id ? { ...row, name: newName, description: newDescription } : row));
    setTeams(updatedRows);
  };
  
  const columns = [
    { field: 'id', headerName: 'ID'  },
    { field: 'name', headerName: 'Team Name',  flex: 1,  editable: true, minWidth: 120 },
    { field: 'description', headerName: 'Team Descrption',  flex: 2,editable: true, minWidth: 120},
  ];

  const handleOkClick = async (event, reason) => {
    if (reason !== 'backdropClick') { 
        axios.post('/api/save_players', teams)
        .then((res) => {
          console.log(res)
          onClose(event,reason)
        }).catch((error) => {
          console.error('There was an error!', error);
        })
      }
  };

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') { 
      setOpen(false); 
    }
  };

  return (
      <Dialog open={open} onClose={onClose}>
          <DialogTitle>User Profile</DialogTitle>
          <DialogContent>
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  disabled
                  value={userInfo.name}
              />
              <TextField
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  value={userInfo.email}
                  disabled
              />
          <Button color="primary" onClick={handleAddRow}>
              Add Team
            </Button>

          <DataGrid
            disableColumnMenu
            autoHeight
            rows={teams}
            columns={columns}
            pageSize={5}
            density={'compact'}
            rowsPerPageOptions={[5]}
            processRowUpdate={(newRow) => {
              handleTeamNameChange(newRow.id, newRow.name, newRow.description);
              return newRow;
            }}
            initialState={{
              columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
              },
            }}

          />
          </DialogContent>
          <DialogActions>
              <Button onClick={onClose} color="primary">
                  Cancel
              </Button>
              <Button onClick={handleOkClick} color="primary">
                  OK
              </Button>
          </DialogActions>
      </Dialog>
  );
};

